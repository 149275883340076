.join-meeting-wrapper{
    /* height: 100vh; */
    min-height: calc(100vh - 60px); /* 60px header + 20 padding bottom */
    width: 100% !important;
    margin: 0 auto;
    padding: 20px 10px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}
.join-meeting-main-container{
    max-width: 380px !important;
    align-items: center;
    box-sizing: border-box;
}
.join-meeting-buttons-container{
    width: inherit;
    align-items: center;
    padding: 20px 0px;
}
.join-meeting-button{
    padding: 0px 10px;
}
.join-meeting-expiracy-container{
}
.join-meeting-expiracy-timer{
    padding-left: 5px;
}
.join-meeting-expiracy-error-container{
    align-items: center;
}
.join-meeting-expiracy-error-msg-container{
    opacity: 0;
    padding-left: 0px;
    transition-delay: 0.6s;
    max-height: 0px;
    overflow: hidden;
    transition: ease-in-out 0.3s opacity, ease-in-out 0.3s padding-left, ease-in-out 0.3s max-height
}
.join-meeting-expiracy-error-msg-container.show{
    opacity: 1;
    padding-left: 10px;
    max-height: 100px;
}
.join-meeting-redirect-button-container{
    opacity: 0;
    padding-top: 0px;
    transition-delay: 0.6s;
    max-height: 0px;
    overflow: hidden;
    transition: ease-in-out 0.3s opacity, ease-in-out 0.3s padding-top, ease-in-out 0.3s max-height

}
.join-meeting-redirect-button-container.show{
    opacity: 1;
    padding-top: 20px;
    max-height: 100px;
}
.join-meeting-instruction-wrapper{
    padding-top: 40px;
    animation: show;
    animation-duration: 0.3s;
}
@keyframes show {
    from{
        opacity: 0;
        height: 0px;
    }
    to{
        opacity: 1;
        height: fit-content;
    }
}