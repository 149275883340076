/* ZOOM MEETING PROVIDER */
/* They are imported here to be the most prioritary ones */
.joinWindowBtn{
    background-color: #3f50b5 !important ;
    border-color: #3f50b5 !important ;
}
.zm-btn--primary{
    /* primary theme color */
    background-color: #3f50b5 !important ;
}
.chat-receiver-list__receiver{
    /* primary theme color */
    background-color: #3f50b5 !important ;
}
.zmu-tabs__tab-bar--active{
    /* primary theme color */
    background-color: #3f50b5 !important ;
}
.zm-checkbox-checked::before{
    /* primary theme color */
    background-color: #3f50b5 !important ;
}
.background-selector__list--active{
    /* primary theme color */
    border-color: #3f50b5 !important ;
}
.join-audio-container__btn{
    /* prevent from overlapping sent_video_container__btn */
    width: fit-content;
}
.zmu-btn--danger{
    /* secondary theme color */
    background-color: #f50057 !important ;
}
.leave-meeting-options__btn--danger{
    /* secondary theme color */
    background-color: #f50057 !important ;
}
.leave-meeting-options__btn--danger:hover{
    /* secondary theme color */
    background-color: #ab003c !important ;
}
.ax-outline-blue{
    /* primary theme color */
    /* border-color: solid 2px #3f50b5 !important ; */
}
.ax-outline{
    /* primary theme color */
    /* outline: solid 2px #3f50b5 !important ; */
}
.meeting-info-container--left-side{
    top: 60px !important
}

@media screen and (max-width: 1119px) {
    .zm-modal{
        /* make modal responsive */
        max-width:380px !important;
        box-sizing: border-box;
        width: 100% !important;
        padding: 20px !important;
    }
    /* All of them inside mini-layout */
    .waiting-pannel{
        max-width: 380px !important;
        height: calc(100vh - 60px) !important;
        min-width: none;
        width: 100% !important;
        /* padding: 20px 10px; */
        min-width: none;
    }
    /* End inside mini-layout
    /* All of them are placed inside .meeting-on-hold */
    .waiting-room-preview{
        display: none !important; /* Prevents from showing preview popUp */
    }
    .waiting-room-container{
        padding: 60px 12px 0px 12px;
    }
    .waiting-room-container .wr-information{
        padding: 0px !important; /* SDK establishes a fixed regardless the screen width 302px */
        text-align: center !important; /* SDK estabishes left */
    }
    .waiting-room-container .wr-footer{
        /* Prevents from showing a overflowed footer */
        display: none !important
    }
    /* End class inside .meeting-on-hold */
    /* Inside zny-paper */
    #settingsDialog{
        width: 380px !important;
        max-height: 100vh !important;
    }
    .settings-dialog{
        max-width: 380px !important;
        max-height: 100vh !important;
        width: 100% !important;
        height: 100% !important;
    }
    .settings-canvas{
        display: none !important;
    }
    .settings-tabs{
        width: 380px !important;
        /* height: 100% !important; */
        flex-direction: column;
        box-sizing: border-box;
    }
    @media screen and (max-width: 320px) {
        #wc-footer .more-button{
            display: none !important;
        }
    }
}