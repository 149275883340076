.join-meeting-instructions-text-link{
    cursor: pointer;
    text-decoration: none;
}
.join-meeting-instruction-info-container{
    border: solid 1px;
    border-radius: 5px;
    padding: 10px;
    width: fit-content !important;
}
.join-meeting-instruction-info-item{
    padding-top: 20px;
}
.join-meeting-instruction-info-labels-container{
    padding-right: 10px
}
.join-meeting-instruction-warning-container{
}