.pop-up-main{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0px;
    left:0px;
    background: rgba(0, 0, 0, 0.575);
    z-index: 1101;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes fade-in {
    from { opacity: 0%;}
    to { opacity: 100%;}
}


.pop-up-main-visible{
    visibility: visible;
    animation: fade-in 0.3s;
}

.pop-up-main-hidden{
    visibility: hidden;
}

.pop-up-modal{
    overflow: hidden;
    overflow-y: auto;
    max-height: 90vh;
    min-height: 100px;
    position:relative;
    height: auto;
    transition: height ease-in-out 0.3s !important;

}

.pop-up-modal-close-icon{
    z-index: 1101;
    position: absolute;
    fill:black;
    left: 10px;
    top: 10px;
    cursor: pointer;
}
