.post-meeting-wrapper{
    min-height: calc(100vh - 60px); /* 60ox header */
    width: 100% !important;
    max-width: 380px;
    margin: 0 auto;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 20px 10px;
}
.post-meeting-animator-container{
    height: 150px;
}
.post-meeting-main-container{
    align-items: center;
    max-width: 380px;
}
.post-meeting-guidance{
    padding-top: 10px
}

.post-meeting-button{
    padding-top: 40px
}