.simple-no-modal-dialog-first-item{
    
}
.simple-no-modal-dialog-item{
    padding-top: 20px;
}
.simple-no-modal-dialog-title{
    font-weight: bold !important;
    font-size: large;
}
.simple-no-modal-dialog-content-text{
    padding-left: 5px;
    padding-right: 5px;
}
.simple-no-modal-dialog-actions{
}
.simple-no-modal-dialog-button{
    padding-left: 20px;
}
.simple-no-modal-dialog-error-toogle{
    text-align: left;
    max-height: 0px;
    opacity: 0%;
    padding: 0px;
    white-space: pre-line;
    transition: max-height 0.3s ease-in-out,opacity 0.3s ease-in-out, padding-left 0.3s ease-in-out;
}
.simple-no-modal-dialog-error-toogle.show {
    max-height: 1000px;
    opacity: 100%;
    padding-left: 10px;
}