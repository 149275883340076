@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Aleo:wght@300;400;700&family=Montserrat:ital,wght@0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,900&family=Noto+Sans:wght@400;700&family=Playfair+Display:wght@800&display=swap'); */
/* @import url('https://db.onlinewebfonts.com/c/6c4202419245188b23a6a2346e9b3a08?family=GT+Super+Display+Bold'); "GT Super Display Bold" */
/* html, body { height: 100% } */
html {
  /* New */
  min-width: 0 !important;
  overflow: initial !important;
  /*  */
}
body {
  /* New */
  min-width: 0 !important;
  overflow: initial !important;
  /*  */
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important; */
  font-family: 'Noto Sans', '"Helvetica Neue"', 'Arial', 'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.index-loading-container{
  height: 100vh;
  width: 100%;
}

/* ZOOM MEETING PROVIDER */
#zmmtg-root {
  display: none;
  /* New */
  min-width: 0 !important;
  /*  */
}
/* New */
iframe#webpack-dev-server-client-overlay{display:none!important}
/*  */