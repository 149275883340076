.join-meeting-error-main-container{
    align-items: center;
    max-width: 380px;
    box-sizing: border-box;
}
.join-meeting-error-guidance{
    padding-top: 10px
}
.join-meeting-error-cause-container{
    width:100%;
    padding: 20px 5px 0px 5px;
    box-sizing: border-box;
    /* text-align: center; */
}
.join-meeting-error-cause{
    padding: 5px;
    font-family:'Courier New', Courier, monospace !important
}
.join-meeting-error-button{
    padding-top: 40px
}